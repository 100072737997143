<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="添加提现账户"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="account">
            <div class="account-div" @click="accountClick">
                <p class="account-title">
                    <input type="text" placeholder="请选择账户类型" v-model="account" disabled>
                </p>
                <p>
                    <van-icon name="arrow-down" color="#ECECEC" size="20"/>
                </p>
            </div>
            <div class="account-div">
                <p class="account-title">
                    <input type="text" placeholder="请输入账号" v-model="phone">
                </p>
            </div>
            <div class="account-div">
                <p class="account-title">
                    <input type="text" placeholder="请输入账户姓名" v-model="name">
                </p>
            </div>
            <p class="button" v-if="!button_show">确定</p>
            <p class="button active" v-else @click="buttonClick">确定</p>
        </div>
        <!--        选择账户-->
        <van-action-sheet
                v-model="show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @select="onSelect"
        />
    </div>
</template>

<script>
    export default {
        name: "Account",
        data() {
            return {
                actions: [{id: '2', name: '支付宝账户'}, {id: '1', name: '微信账户'},],
                show: false,
                account: '',
                type: '',
                phone: '',
                name: '',
                button_show: false,
            }
        },
        watch: {
            account(e) {
                if (e && this.phone && this.name) {
                    this.button_show = true
                } else {
                    this.button_show = false
                }
            },
            phone(e) {
                if (e && this.phone && this.account) {
                    this.button_show = true
                } else {
                    this.button_show = false
                }
            },
            name(e) {
                if (e && this.phone && this.account) {
                    this.button_show = true
                } else {
                    this.button_show = false
                }
            },
        },
        methods: {
            onSelect(item) {
                this.account = item.name
                this.type = item.id
            },
            accountClick() {
                this.show = true
            },
            buttonClick() {
                this.$router.replace({
                    path: '/withdrawal',
                    query: {
                        name: this.name,
                        type: this.type,
                        phone: this.phone,
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .active {
        opacity: 1 !important;
    }

    .button {
        width: 670px;
        height: 80px;
        background: rgba(77, 119, 253, 1);
        opacity: 0.5;
        border-radius: 40px;
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .account-title::-webkit-input-placeholder {
        color: #BFC2CC;
    }

    .account-title input {
        color: #303133;
        font-size: 28px;
        background: none;
        width: 100%;
    }

    .account-title {
        color: #BFC2CC;
        font-size: 28px;
    }

    .account-div {
        border: 1px solid rgba(236, 236, 236, 1);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        height: 80px;
        margin-bottom: 40px;
    }

    .account {
        padding: 40px;
    }
</style>